<template>
  <p>Autenticando ...</p>
</template>

<script>
export default {
  name: 'Auth',

  mounted() {
    this.auth()
  },

  methods: {
    async auth() {
      this.$store.commit('updateUser', {
        token: this.$route.query.token,
      })
      await this.$store.dispatch('getOrganization')
      await this.$store.dispatch('getUserInfo')
      this.$router.push({ path: '/' })
    },
  },
}
</script>
